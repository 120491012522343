import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#000',
        },
        secondary: {
            main: '#D99B45',
        },
        primary: {
            main: '#E37365',
        },
    },
});
