import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { readObjectPrototype } from "./utilities";

export const useTouch = () => {

    const [touchEvent, setTouchEvent] = useState({
        // event: '',
        // location: {
        //     x: 0,
        //     y: 0,
        //     targetWidth: 2560,
        //     targetHeight: 1440,
        //     tsPlayerEventCaptured: 0
        // },
        data: {}
    });

    const handleTouchEvent = useCallback((e: TouchEvent) => {
        console.log(JSON.stringify(e));
        const target = e.target as HTMLVideoElement;
        switch (e.type) {
            case 'touchstart':

                setTouchEvent((prevState) => {
                    return {
                        data: {
                            ...readObjectPrototype(e),
                            touches: Object.keys(e.targetTouches).map(x => { return { ...readObjectPrototype(e.targetTouches[x as unknown as number]) } }),
                            changedTouches: e.changedTouches,
                            targetTouches: e.targetTouches
                        }
                    }
                });
                // setMouseEvent((prevState) => {
                //     return {
                //         event: "mouseLocation",
                //         location: {
                //             ...prevState.location,
                //             targetWidth: target.offsetWidth,
                //             targetHeight: target.offsetHeight,
                //             tsPlayerEventCaptured: Date.now(),
                //             x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                //             y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                //         },
                //         data: { ...readObjectPrototype(e) }
                //     };
                // });

                break;

            case 'touchend':

                setTouchEvent((prevState) => {
                    return {
                        data: {
                            ...readObjectPrototype(e),
                            touches: e.targetTouches,
                            changedTouches: e.changedTouches,
                            targetTouches: e.targetTouches
                        }
                    }
                });
                // setMouseEvent((prevState) => {
                //     return {
                //         event: "mouseDown", location: {
                //             ...prevState.location,
                //             targetWidth: target.offsetWidth,
                //             targetHeight: target.offsetHeight,
                //             tsPlayerEventCaptured: Date.now(),
                //             x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                //             y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                //         }, data: { ...readObjectPrototype(e) }
                //     }
                // });
                break;

            case 'touchmove':

                setTouchEvent((prevState) => {
                    return {
                        data: {
                            ...readObjectPrototype(e),
                            touches: Object.keys(e.targetTouches).map(x => { return { ...readObjectPrototype(e.targetTouches[x as unknown as number]) } }),
                            changedTouches: e.changedTouches,
                            targetTouches: e.targetTouches
                        }
                    }
                });
                // setMouseEvent((prevState) => {
                //     return {
                //         event: "mouseUp", location: {
                //             ...prevState.location,
                //             targetWidth: target.offsetWidth,
                //             targetHeight: target.offsetHeight,
                //             tsPlayerEventCaptured: Date.now(),
                //             x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                //             y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                //         }, data: { ...readObjectPrototype(e) }
                //     }
                // });
                break;

            case 'touchcancel':

                setTouchEvent((prevState) => {
                    return {
                        data: {
                            ...readObjectPrototype(e),
                            touches: e.targetTouches,
                            changedTouches: e.changedTouches,
                            targetTouches: e.targetTouches
                        }
                    }
                });
                // setMouseEvent((prevState) => {
                //     return {
                //         event: "mouseWheel", location: {
                //             ...prevState.location,
                //             targetWidth: target.offsetWidth,
                //             targetHeight: target.offsetHeight,
                //             tsPlayerEventCaptured: Date.now(),
                //             x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                //             y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                //         }, data: { ...readObjectPrototype(e) }
                //     }
                // });
                break;
        }
    }, []);

    const ref = useRef<HTMLVideoElement | null>(null);

    const callbackRef = useCallback(
        (node) => {
            if (ref.current) {
                console.log('remove touch listeners');
                ref.current.removeEventListener("touchstart", handleTouchEvent);
                ref.current.removeEventListener("touchend", handleTouchEvent);
                ref.current.removeEventListener("touchmove", handleTouchEvent);
                ref.current.removeEventListener("touchcancel", handleTouchEvent);
            }

            ref.current = node;

            if (ref.current) {
                console.log('adding touch listeners');
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
            }
        },
        [handleTouchEvent]
    );

    return { ref: ref, cb: callbackRef, touchEvent };
};

export const Touch = () => {
    const { cb, touchEvent } = useTouch();
    const [dotLocation, setDotLocation] = useState({ x: 0, y: 0 });
    const [debug, setDebug] = useState<boolean>(true);

    // useEffect(() => {
    //     const newX = mouseEvent.location.x * (window.innerWidth / mouseEvent.location.targetWidth);
    //     const newY = mouseEvent.location.y * (window.innerHeight / mouseEvent.location.targetHeight);
    //     setDotLocation({ x: newX, y: newY });
    // }, [mouseEvent]);

    return <>
        <Box sx={{ height: "3px", width: "3px", backgroundColor: "#ff0000", position: "absolute", left: `` + dotLocation.x + 'px', top: `` + dotLocation.y + 'px' }}></Box>
        <Box sx={{ m: "2rem" }}>
            <Typography variant="h3">Touch Experiments</Typography>
            <Stack direction="row">
                <Box sx={{
                    maxHeight: "500px",
                    maxWidth: "900px",
                    backgroundColor: "#333",
                    "> div > video": {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: 'contain',
                        width: '900px',
                        height: '500px'
                    }
                }}>
                    <Box ref={cb} ><video playsInline autoPlay></video></Box>
                    <Button onClick={() => { setDebug((prev) => !prev) }}>Debug</Button>
                </Box>
                {debug ? <Box sx={{ mt: "2rem", mb: "2rem" }}>
                    <pre>{JSON.stringify(touchEvent, null, 4)}</pre>
                </Box> : ``}
            </Stack>
        </Box>
    </>
}
