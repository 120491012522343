import { Button, CardMedia, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export const Home = () => {

    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >

            <Grid item xs={3}>
                <CardMedia component="img" image={process.env.PUBLIC_URL + "/playcast logo small.png"} alt="PlayCast Demo" sx={{ align: "center", maxHeight: "50vh", maxWidth: "300px", mb: "2rem" }} />
            </Grid>
            <Grid item xs={3}>
                <Button component={Link} to="/host" variant="contained" sx={{ mr: "1rem" }}>Host Demo</Button>
                <Button component={Link} to="/player" variant="contained">Player Demo</Button>
            </Grid>
        </Grid>
    </>
}