
import { CardMedia, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const ControllerMonitor = () => {

    const [socketUrl] = useState('wss://controller.playcast.io/game');

    const { lastMessage, readyState } = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => true,
    });

    const peerId = 'trey-playcast-io';
    const [message, setMessage] = useState<string>('Waiting...');

    const processMesssage = async (message: Blob) => {
        const data = await message.text();
        setMessage(JSON.stringify(JSON.parse(data), null, 4));
    }

    useEffect(() => {
        if (!lastMessage) return;
        processMesssage(lastMessage.data);
    }, [lastMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING as number]: 'Connecting',
        [ReadyState.OPEN as number]: 'Open',
        [ReadyState.CLOSING as number]: 'Closing',
        [ReadyState.CLOSED as number]: 'Closed',
        [ReadyState.UNINSTANTIATED as number]: 'Uninstantiated',
      }[readyState];
    
    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <CardMedia component="img" image={process.env.PUBLIC_URL + "/playcast logo small.png"} alt="PlayCast Demo" sx={{ align: "center", maxHeight: "20vh", maxWidth: "300px", mt: "1rem", mb: "0.25rem" }} />
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography sx={{ mb: "1rem" }}>Monitoring Controller: {peerId} [{connectionStatus}]</Typography>
                <Typography>Latest Message</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <pre>{ message || 'Invalid message' }</pre>
            </Grid>
        </Grid>
    </>
}
