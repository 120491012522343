import env from 'react-dotenv';
import { v1 as uuid } from 'uuid';

export const getRoom = () => {
    // First, use environment
    let room: string = env.ROOM;

    // Consider hostname alternative if no environemnt variable
    if (!room) {
        switch (window.location.host.split(':')[0]) {
            case 'peerclient.pangadev.com':
                room = 'e3a15cbf-4e1c-494a-b288-51305e5eb999';
                break;

            default:
            // I guess not!
        }
    }

    class urlProps extends URLSearchParams {
        room?: string;
    }

    // Allow URL override
    const urlParams = new Proxy(new urlProps(window.location.search), {
        get: (searchParams, prop: keyof urlProps) =>
            searchParams.get(prop as string),
    });

    // Take URL room, or room we figured out above, or a new UUID as fallback
    room = urlParams.room || room || uuid();

    return room;
};

export const readObjectPrototype = (x: any) => {
    const getters = Object.getPrototypeOf(x);
    const props = Object.keys(getters);

    let output: any = {};
    for (let i = 0, len = props.length; i < len; i++) {
        if (['number', 'string', 'boolean'].includes(typeof x[props[i]])) {
            output[props[i]] = x[props[i]];
        }
    }
    return output;
};
